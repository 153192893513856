import Page from './Page';
import controls from './controls';


function App() {
  // Inline styles for simplicity, you may want to use a CSS file in a real project

  let urlData = {};

  window.location.search.replaceAll("?","").split("&").forEach(element => {
    const elementList = element.split("=");
    urlData[elementList[0]] = elementList[1];
  })

  // console.log(urlData);

  controls.agentId = urlData.agentId || "";
  controls.customerId = urlData.customerId || ""; //sets the frecall Room ID.

  console.log({controls});

  return (
    <Page/>
  )

}

export default App;
