import React, { useState } from 'react';
import {Card, Typography, Rating, TextField, Button, Box } from '@mui/material';
import cdcarelogo from './cdcarelogo.png'; // Ensure you have the logo in your project's directory
import { createTheme, ThemeProvider } from '@mui/material/styles';
import submitCSRRating from "./firebase";
import controls from './controls';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6F00', // Red color you want for the border and other primary elements
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF6F00', // Border color when the TextField is focused
          },
        },
      },
    },
  },
});


export default function Page() {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [submited, setSubmit] = useState(false);

  
      // Handle change in rating
      const handleRatingChange = (event, newValue) => {
        setRating(newValue);
      };
    
      // Handle change in comment
      const handleCommentChange = (event) => {
        setComment(event.target.value);
      };
    
      // Handle form submission
      const handleSubmit = () => {
  
        submitCSRRating({
          agentId: controls.agentId,
          customerId: controls.customerId,
          rating: rating,
          comment: comment,
        });
  
        console.log(`Rating: ${rating}, Comment: ${comment}`);

        setSubmit(true);

        // window.close();
        // You can also handle submission logic here
      };
  
    const styles = {
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      },
      card: {
        maxWidth: '400px',
        padding: '32px',
        borderRadius: '16px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        backgroundColor: '#fff', // Adjust the color based on your design
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      logo: {
        marginBottom: '24px',
        width: '120px', // Adjust according to the actual size of your logo
        height: 'auto',
      },
      title: {
        color: '#333', // Adjust the color based on your design
        marginBottom: '24px',
      },
      rating: {
        marginBottom: '24px',
        color: '#FF6F00',
       fontSize: '50px',
      },
      commentBox: {
        width: '100%',
        marginBottom: '24px',
      },
      submitButton: {
        width: '100%',
        padding: '16px 0', // Optional: adjust padding for better visual appeal
        backgroundColor: '#FF6F00', // Adjust the color based on your design
        color: '#fff',
      }
    };
  
    return (
      <ThemeProvider theme={theme}>
  
      <div style={styles.container}>
        <Card style={styles.card}>
          <img src={cdcarelogo} alt="DCDcare Logo" style={styles.logo} />
          <Typography variant="h6" style={styles.title}>
            {submited?"Thank you.":"Kindly rate my service to you."}
          </Typography>

          {submited?
          <div></div>: 
          <Box>
            <Box display="flex" justifyContent="center" width="100%">
                <Rating name="simple-controlled"
                        style={styles.rating}
                        value={rating}
                        onChange={handleRatingChange}
                    />
            </Box>
           
            <TextField
                style={styles.commentBox}
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                placeholder="You can also drop a comment..."
                value={comment}
                onChange={handleCommentChange}
            />
            <Button variant="contained" style={styles.submitButton} onClick={handleSubmit}
    >
                SUBMIT
            </Button>
          </Box>}
        </Card>
      </div>
      </ThemeProvider>
  
    );
}
