import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = initializeApp({
    apiKey: "AIzaSyBGyT6Wm224IcUnimVO3WtA5dqVFsSlRC8",
  authDomain: "cdcare-mobile-app.firebaseapp.com",
  databaseURL: "https://cdcare-mobile-app.firebaseio.com",
  projectId: "cdcare-mobile-app",
  storageBucket: "cdcare-mobile-app.appspot.com",
  messagingSenderId: "1085471493523",
  appId: "1:1085471493523:web:e7ca972b487562fcad2c66",
  measurementId: "G-F1SLVKZQJZ"
  });
const functions = getFunctions(app);

const submitCSRRating = httpsCallable(functions, 'submitCSRRating');

export default submitCSRRating;
